import * as firebase from 'firebase/app';
import loadFirebase from './loadFirebase';

// ログイン中はヘッダのロゴクリックで / ではなく /home へ
loadFirebase.then(() => {
  firebase.auth().onAuthStateChanged(async (firebaseUser) => {
    if (firebaseUser) {
      const a = document.getElementById('hearder-logo-link');
      if (a) {
        a.setAttribute('href', '/home');
      }
    }
  });
});
